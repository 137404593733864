import * as React from 'react';
import cn from 'classnames';
import Cards from 'react-credit-cards-2';
import { getTypeInfo } from 'credit-card-type';
import 'react-credit-cards-2/es/styles-compiled.css';
import { CreditCardType } from 'credit-card-type/dist/types';
import Button from '../Button/Button';
import { WalletPaymentSource } from 'contexts/UserContextContainer/types';
import { IconCheckmark } from 'styleguide/icons';
import { CreditCard } from 'bundles/App/pages/Account/CreditCards/types';

export interface Props {
  wallet: WalletPaymentSource;
  preview?: boolean;
  onDefaultClick: (id: number) => void;
  onDeleteClick: (id: number) => void;
}

const isExpired = (expMonth: number, expYear: number): boolean => {
  const date = new Date();
  const curYear: number = date.getFullYear();
  const curMonth: number = date.getMonth() + 1; // getMonth returns 0 based month

  if (curYear < expYear) {
    return false;
  }
  if (curYear === expYear && curMonth <= expMonth) {
    return false;
  }
  return true;
};

const formatCardName = (name: string): string => (name === 'master' ? 'mastercard' : name);

const CreditCardItem = ({ wallet, preview = true, ...props }: Props) => {
  const creditCard: CreditCard = wallet.paymentSource;
  const ccInfo: CreditCardType = getTypeInfo(formatCardName(creditCard.ccType));
  const valid = !!ccInfo;
  const expired: boolean = isExpired(parseInt(creditCard.month, 10), parseInt(creditCard.year, 10));

  return (
    <div
      className={cn(
        !valid && '!border-gray-600 !bg-gray-100',
        expired
          ? 'border-red-400 bg-red-50 hover:bg-red-100'
          : 'border-gray-200 bg-slate-50 hover:border-gray-400 hover:bg-slate-100',
        'flex flex-col rounded-xl border border-solid p-4 shadow-lg ' +
          'group cursor-default select-none transition duration-300 ease-out hover:shadow-xl',
      )}
    >
      <div className="scale-95 p-2 transition duration-300 ease-out group-hover:scale-100">
        <Cards
          preview={preview}
          issuer={creditCard.ccType}
          number={creditCard.lastDigits.padStart(valid ? ccInfo.lengths[0] : 16, '*')}
          expiry={`${creditCard.month.padStart(2, '0')}${creditCard.year}`}
          name={creditCard.name}
        />
      </div>
      <div className={cn(!valid && '!text-gray-400 !line-through', 'p-2')}>
        <div className="font-hvHeavy text-base font-black">{valid ? ccInfo.niceType : 'INVALID CARD'}</div>
        <div className="text-sm">Credit Card ending in ({creditCard.lastDigits})</div>
        <div className={cn(expired && valid ? 'font-bold text-red-600' : '', 'text-sm')}>
          <span>Expiration: {`${creditCard.month.padStart(2, '0')}/${creditCard.year}`}</span>
          {expired && <span className="font-hvBold">&nbsp;&nbsp;Expired!</span>}
        </div>
      </div>
      <div className="flex justify-evenly gap-1">
        <Button size="sm" color="orange" type="button" outline onClick={() => props.onDeleteClick(wallet.id)}>
          Delete
        </Button>
        {valid &&
          !expired &&
          (!wallet.default ? (
            <Button
              size="sm"
              color="dark"
              type="button"
              outline
              onClick={() => props.onDefaultClick(wallet.id)}
            >
              Make Default
            </Button>
          ) : (
            <Button className="!opacity-100" type="button" color="blue" size="sm" outline disabled>
              <div className="inline-flex space-x-1">
                <IconCheckmark fill="94a3b8" color="blue" />
                <div>Default</div>
              </div>
            </Button>
          ))}
      </div>
    </div>
  );
};

export default CreditCardItem;
