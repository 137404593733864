import apiCall from 'app/libs/utils/api/apiCall';
import { buildUrl } from 'libs/utils/api';
import { BasicResponse, CreditCardsResponse } from '../types';
import { SharedErrors } from 'libs/utils/api/types';

// /credit_cards
export const creditCardsScope = (path?) => `/credit_cards${path || ''}`;

// /credit_cards/:id
export const destroyCreditCard = id => {
  const url = buildUrl(creditCardsScope(`/${id}`));
  return apiCall.delete<BasicResponse, SharedErrors>({ url, data: { id }, unscoped: true });
};

// /credit_cards/:id/make_default
export const makeCreditCardDefault = id => {
  const url = buildUrl(creditCardsScope(`/${id}/make_default`));
  return apiCall.post<CreditCardsResponse, SharedErrors>({ url, data: { id }, unscoped: true });
};
