import * as React from 'react';
import CreditCardItem from 'styleguide/components/CreditCardItem/CreditCardItem';
import TitleContainer from '../TitleContainer/TitleContainer';
import { Status } from 'libs/utils/api/types';
import Footer from 'styleguide/components/Footer/Footer';
import { destroyCreditCard, makeCreditCardDefault } from 'api/account/creditCards';
import toast from 'utils/toast';
import UserContext from 'contexts/UserContextContainer/UserContext';
import { formatMoney } from 'utils/money';

const CreditCardsPage = () => {
  const { currentUser, deleteCreditCard, updateCreditCards } = React.useContext(UserContext);

  const deleteCreditCardHandler = (id: number) => {
    destroyCreditCard(id).then(res => {
      if (res.status === Status.Ok) {
        deleteCreditCard(id);
      } else {
        toast.error('There was problem while processing your request.');
      }
    });
  };

  const makeCreditCardDefaultHandler = (id: number) => {
    makeCreditCardDefault(id).then(res => {
      if (res.status === Status.Ok) {
        updateCreditCards(res.payload.walletPaymentSources);
      } else {
        toast.error('There was problem while processing your request.');
      }
    });
  };

  return (
    <>
      <TitleContainer title="Saved payment methods" />
      <div className="mx-auto max-w-base pl-1">
        {!!currentUser.availableStoreCreditTotal && currentUser.availableStoreCreditTotal > 0 && (
          <div className="px-1 pt-1 text-center font-hvMedium text-lg text-green-700 md:px-4 md:pt-4 md:text-left md:text-2xl lg:px-8 lg:pt-8 lg:text-4xl">
            Available Store Credit: {formatMoney(currentUser.availableStoreCreditTotal)}
          </div>
        )}
        <div className="flex flex-row flex-wrap justify-center gap-8 p-1 md:justify-start md:p-4 lg:p-8">
          {currentUser.walletPaymentSources.map(wallet => (
            <CreditCardItem
              key={wallet.id}
              wallet={wallet}
              onDeleteClick={deleteCreditCardHandler}
              onDefaultClick={makeCreditCardDefaultHandler}
            />
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default CreditCardsPage;
